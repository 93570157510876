<template>
  <PageCard
    pageName="Large Downloads Report"
    :iconClass="['cuis-spreadsheet', 'fa-lg']"
  >
    <template slot="page_content">
      <v-wait for="fetchingReport">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="9"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <div class="row">
            <div class="col-sm-3 text-left mb-3 mt-2">
              <b-input-group>
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <b-form-input
                  type="text"
                  v-model="filterText"
                  @keydown.native.stop
                  placeholder="Search"
                ></b-form-input>
              </b-input-group>
            </div>

            <div class="col-sm-12">
              <b-table
                :items="dataProvider"
                :fields="tableHeaders"
                :filter="filterText"
                :filter-included-fields="filterOn"
                @filtered="afterFilter"
                :show-empty="true"
                :current-page="currentPage"
                :per-page="perPage"
                empty-text="No records found."
                responsive
                striped
                hover
                small
              >
                <template v-slot:cell(actions)="data">
                  <div class="text-center px-3">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      @click="setSelectedItem(data.item)"
                      v-b-modal.viewElements
                    >
                      View
                    </button>
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col md="6">
                  <b-pagination
                    :total-rows="
                      filteredUsers ? filteredUsers.length : totalRows
                    "
                    :per-page="perPage"
                    v-model="currentPage"
                  >
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </v-wait>
      <b-modal
        size="sm"
        id="viewElements"
        ref="viewElements"
        title="Report Data Elements"
        @ok="closeModal"
        ok-only
      >
        <ul>
          <li
            v-for="(element, index) in reportElements"
            :key="index"
            class="pb-1"
          >
            <div>
              {{ element.name }}
            </div>
          </li>
        </ul>
      </b-modal>
    </template>
  </PageCard>
</template>
<script>
// ui components
import PageCard from '@/modules/core/components/layouts/PageCard'
export default {
  name: 'LargeDownloadsReport',
  components: {
    PageCard
  },
  data() {
    return {
      totalRows: 0,
      users: [],
      currentPage: 1,
      perPage: 20,
      allUsers: [],
      filterOn: ['user_id', 'first_name', 'last_name', 'group_name', 'email'],
      filterText: null,
      filteredUsers: null,
      reportElements: []
    }
  },
  computed: {
    tableHeaders() {
      return [
        {
          key: 'large_download_alert_id',
          label: 'ID',
          sortable: true,
          thStyle: { width: '4%' }
        },
        {
          key: 'user_name',
          label: 'User Name',
          sortable: true,
          thStyle: { width: '10%' }
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          thStyle: { width: '10%' }
        },
        {
          key: 'group_name',
          label: 'Group',
          sortable: true,
          thStyle: { width: '10%' }
        },
        {
          key: 'fi_count',
          label: '# of FIs',
          sortable: true,
          thStyle: { width: '10%' }
        },
        {
          key: 'elements_count',
          label: '# of Data Elements',
          sortable: true,
          thStyle: { width: '10%' }
        },
        {
          key: 'contact_count',
          label: '# of FI Contacts',
          sortable: true,
          thStyle: { width: '10%' }
        },
        {
          key: 'actions',
          label: 'Report Data Elements',
          sortable: false,
          class: 'text-center',
          thStyle: { width: '18%' }
        }
        // {
        //   key: 'created_at',
        //   label: 'Date',
        //   sortable: true,
        //   thStyle: { width: '10%' }
        // }
      ]
    }
  },
  methods: {
    closeModal() {
      this.reportElements = []
      this.$refs.viewElements.hide()
    },
    setSelectedItem(item) {
      this.reportElements = item.report_elements_list
    },
    dataProvider(ctx) {
      this.filteredUsers = null
      if (this.filterText && this.filterText.trim().length < 1) {
        this.filterText = null
      }
      return this.$http
        .get('/api/super_admin/advanced_queries/large_downloads', {
          params: {
            page: ctx.currentPage,
            sorting_column: ctx.sortBy,
            sorting_order: ctx.sortDesc ? 'desc' : 'asc',
            search: this.filterText
          },
          handleErrors: false
        })
        .then(res => {
          this.totalRows = res.data.total_downloads
          return res.data.downloads || []
        })
    },
    afterFilter: function(filteredItems) {
      this.filteredUsers = filteredItems
      this.currentPage = 1
    }
  }
}
</script>
<style lang="scss">
.truncate {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
